export const SERVER_ERROR = 'SERVER_ERROR';
export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const STATIC_PAGE = 'STATIC_PAGE';
export const WHO_AM_I = 'WHO_AM_I';
export const HOME = 'HOME';
export const DISCUSSIONS = 'DISCUSSIONS';
export const DIRECT = 'DIRECT';
export const GET_SAVES = 'GET_SAVES';
export const GET_USER_FEED = 'GET_USER_FEED';
export const SHOW_MORE_COMMENTS = 'SHOW_MORE_COMMENTS';
export const SHOW_MORE_LIKES = 'SHOW_MORE_LIKES';
export const SHOW_MORE_LIKES_ASYNC = 'SHOW_MORE_LIKES_ASYNC';
export const SHOW_MORE_LIKES_SYNC = 'SHOW_MORE_LIKES_SYNC';
export const TOGGLE_EDITING_POST = 'TOGGLE_EDITING_POST';
export const CANCEL_EDITING_POST = 'CANCEL_EDITING_POST';
export const SAVE_EDITING_POST = 'SAVE_EDITING_POST';
export const DELETE_POST = 'DELETE_POST';
export const TOGGLE_COMMENTING = 'TOGGLE_COMMENTING';
export const ADD_COMMENT = 'ADD_COMMENT';
export const LIKE_POST = 'LIKE_POST';
export const UNLIKE_POST = 'UNLIKE_POST';
export const HIDE_POST = 'HIDE_POST';
export const UNHIDE_POST = 'UNHIDE_POST';
export const SAVE_POST = 'SAVE_POST';
export const UNSAVE_POST = 'UNSAVE_POST';
export const TOGGLE_MODERATING_COMMENTS = 'TOGGLE_MODERATING_COMMENTS';
export const DISABLE_COMMENTS = 'DISABLE_COMMENTS';
export const ENABLE_COMMENTS = 'ENABLE_COMMENTS';
export const TOGGLE_EDITING_COMMENT = 'TOGGLE_EDITING_COMMENT';
export const SAVE_EDITING_COMMENT = 'SAVE_EDITING_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const CREATE_POST = 'CREATE_POST';
export const ADD_ATTACHMENT_RESPONSE = 'ADD_ATTACHMENT_RESPONSE';
export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_USER_PICTURE = 'UPDATE_USER_PICTURE';
export const GET_SINGLE_POST = 'GET_SINGLE_POST';
export const GET_POST_BACKLINKS = 'GET_POST_BACKLINKS';
export const BAN = 'BAN';
export const UNBAN = 'UNBAN';
export const SUBSCRIBE = 'SUBSCRIBE';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const SEND_SUBSCRIPTION_REQUEST = 'SEND_SUBSCRIPTION_REQUEST';
export const GET_USER_COMMENTS = 'GET_USER_COMMENTS';
export const GET_USER_LIKES = 'GET_USER_LIKES';
export const TOGGLE_HIDDEN_POSTS = 'TOGGLE_HIDDEN_POSTS';
export const GET_USER_SUBSCRIBERS = 'GET_USER_SUBSCRIBERS';
export const GET_USER_SUBSCRIPTIONS = 'GET_USER_SUBSCRIPTIONS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const CREATE_GROUP = 'CREATE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_PICTURE = 'UPDATE_GROUP_PICTURE';
export const ACCEPT_GROUP_REQUEST = 'ACCEPT_GROUP_REQUEST';
export const REJECT_GROUP_REQUEST = 'REJECT_GROUP_REQUEST';
export const ACCEPT_USER_REQUEST = 'ACCEPT_USER_REQUEST';
export const REJECT_USER_REQUEST = 'REJECT_USER_REQUEST';
export const RESET_POST_CREATE_FORM = 'RESET_POST_CREATE_FORM';
export const RESET_GROUP_CREATE_FORM = 'RESET_GROUP_CREATE_FORM';
export const RESET_GROUP_UPDATE_FORM = 'RESET_GROUP_UPDATE_FORM';
export const RESET_USER_SETTINGS_FORM = 'RESET_USER_SETTINGS_FORM';
export const REALTIME_USER_UPDATE = 'REALTIME_USER_UPDATE';
export const REALTIME_POST_NEW = 'REALTIME_POST_NEW';
export const REALTIME_POST_UPDATE = 'REALTIME_POST_UPDATE';
export const REALTIME_POST_DESTROY = 'REALTIME_POST_DESTROY';
export const REALTIME_POST_HIDE = 'REALTIME_POST_HIDE';
export const REALTIME_POST_UNHIDE = 'REALTIME_POST_UNHIDE';
export const REALTIME_POST_SAVE = 'REALTIME_POST_SAVE';
export const REALTIME_POST_UNSAVE = 'REALTIME_POST_UNSAVE';
export const REALTIME_COMMENT_NEW = 'REALTIME_COMMENT_NEW';
export const REALTIME_COMMENT_UPDATE = 'REALTIME_COMMENT_UPDATE';
export const REALTIME_COMMENT_DESTROY = 'REALTIME_COMMENT_DESTROY';
export const REALTIME_LIKE_NEW = 'REALTIME_LIKE_NEW';
export const REALTIME_LIKE_REMOVE = 'REALTIME_LIKE_REMOVE';
export const UNSUBSCRIBE_FROM_GROUP = 'UNSUBSCRIBE_FROM_GROUP';
export const PROMOTE_GROUP_ADMIN = 'PROMOTE_GROUP_ADMIN';
export const DEMOTE_GROUP_ADMIN = 'DEMOTE_GROUP_ADMIN';
export const REVOKE_USER_REQUEST = 'REVOKE_USER_REQUEST';
export const UPDATE_HIGHLIGHTED_COMMENTS = 'UPDATE_HIGHLIGHTED_COMMENTS';
export const BLOCKED_BY_ME = 'BLOCKED_BY_ME';
export const GET_SUMMARY = 'GET_SUMMARY';
export const GET_USER_SUMMARY = 'GET_USER_SUMMARY';
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const GET_UNREAD_DIRECTS = 'GET_UNREAD_DIRECTS';
export const MARK_DIRECTS_AS_READ = 'MARK_DIRECTS_AS_READ';
export const GET_COMMENT_LIKES = 'GET_COMMENT_LIKES';
export const LIKE_COMMENT = 'LIKE_COMMENT';
export const UNLIKE_COMMENT = 'UNLIKE_COMMENT';
export const CACHE_PAGE = 'CACHE_PAGE';
