import React from 'react';
import { Link } from 'react-router';
import { HashTag, Email, Mention, Link as TLink, Arrows } from 'social-text-tokenizer';

import UserName from './user-name';
import { CustomLink, tokenize } from '../../utils/links';

const Linkify = ({ userHover, arrowHover, children }) => tokenize(children).map((token, i) => {
  if (token instanceof TLink) {
    const link = new CustomLink(token.offset, token.text);

    let Component;
    const props = { key: i };
    if (link.isLocal) {
      Component = Link;
      props.to = link.href;
    } else {
      Component = (props) => <a {...props} target="_blank" rel="noopener">{props.children}</a>;
      props.href = link.href;
    }

    const [visiblePart, hiddenPart] = link.display;
    if (hiddenPart.length > 0) {
      props.className = 'shortened-link';
      return <Component {...props}>{visiblePart}<del>{hiddenPart}</del></Component>;
    }

    return <Component {...props}>{visiblePart}</Component>;
  }

  if (token instanceof Mention) {
    return (
      <UserName
        key={i}
        username={token.text.slice(1).toLowerCase()}
        display={token.text}
        userHover={userHover}/>
    );
  }

  if (token instanceof Arrows && arrowHover) {
    const length = Number(token.text.match(/\d+/)?.[0]) || token.text.length; // Support both "^^^" and "^12"
    return (
      <span
        key={i}
        className={'reference-arrow'}
        onClick={() => arrowHover.click(length)} // eslint-disable-line react/jsx-no-bind
        onMouseEnter={() => arrowHover.hover(length)} // eslint-disable-line react/jsx-no-bind
        onMouseLeave={arrowHover.leave}
      >
        {token.text}
      </span>
    );
  }

  if (token instanceof Email) {
    return <a key={i} href={`mailto:${token.text}`}>{token.pretty}</a>;
  }

  if (token instanceof HashTag) {
    return <Link key={i} to={{ pathname: '/search', query: { q: token.text } }}>{token.text}</Link>;
  }

  return token.text;
});

export default Linkify;
