import {
  combine, withText,
  hashTags, emails, mentions, links, arrows,
  Link as TLink
} from 'social-text-tokenizer';

import config from '../../config/config';

const MAX_URL_LENGTH = 50;

const SHORT_LINK_GLOBAL = /\/[a-z0-9-]{3,35}\/[0-9a-f]{6,10}(?:#[0-9a-f]{4,6})?/gi;
const SHORT_LINK_EXACT = /^\/[a-z0-9-]{3,35}\/[0-9a-f]{6,10}(?:#[0-9a-f]{4,6})?$/i;

// Borrowed from non-exported part of social-text-tokenizer
// (it's effectively range `spacesAndPunctuation`, but with [#@-_/] removed)
const SHORT_LINK_BOUNDARY = /[\u0000-\u0022\u0024-\u002c\u002e\u003a-\u003f\u005b-\u005e\u0060\u007b-\u007e\u0080-\u00bf\u1680\u2000-\u200a\u2010-\u2029\u202f-\u205f\u3000]/;

// Top 20 TLDs (Aug 2023, https://w3techs.com/technologies/overview/top_level_domain)
const tldRe = 'com|org|ru|net|de|uk|br|jp|fr|au|it|in|pl|nl|ca|ir|ua|cz|es|co';

const shortLinks = () => (s) => [...s.matchAll(SHORT_LINK_GLOBAL)].map(m => {
  const link = m[0];
  const charBefore = m.input[m.index - 1];
  const charAfter = m.input[m.index + link.length];

  if (charBefore !== undefined && !SHORT_LINK_BOUNDARY.test(charBefore)) {
    return link;
  }
  if (charAfter !== undefined && !SHORT_LINK_BOUNDARY.test(charAfter)) {
    return link;
  }

  return new TLink(m.index, link);
});

export class CustomLink extends TLink {
  path = null;
  isShortish = false; // Short link, optionally prepended with local domain
  isLocal = false; // Short-ish OR using local domain

  constructor(offset, text) {
    super(offset, text);

    // 1. Short link, starts with '/'
    if (text[0] === '/') {
      this.isShortish = true;
      this.isLocal = true;
      this.path = text;
      return;
    }

    // 2. Long link, starts with http://hostname, or just hostname
    const m = text.match(/^(?:https?:\/\/)?([^/]+)(.*)$/i);
    if (m) {
      this.path = m[2] || '/';
      const hostname = m[1].toLowerCase();
      const isDomainFamiliar = config.siteDomains.indexOf(hostname) > -1;
      this.isLocal = isDomainFamiliar && this.path !== '/';
      this.isShortish = this.isLocal && SHORT_LINK_EXACT.test(this.path);
      return;
    }

    console.error(`Unexpected link format: "${text}"`);
  }

  get display() {
    if (this.isShortish) {
      return [this.path, ''];
    }

    let visiblePart = this.shorten(MAX_URL_LENGTH);
    if (visiblePart.slice(-1) === '\u2026') {
      visiblePart = visiblePart.slice(0, -1);
    }
    const hiddenPart = this.pretty.slice(visiblePart.length);
    return [visiblePart, hiddenPart];
  }

  get href() {
    return this.isLocal ? this.path : super.href;
  }
}

// Break down the string into hashtags, emails, mentions, links and arrows,
// then include Text tokens in between
export const tokenize = withText(combine(
  hashTags(),
  emails(),
  mentions(),
  links({ tldRe }),
  shortLinks(),
  arrows(/\u2191+|\^([1-9]\d*|\^*)/g)
));

