const PROD_API = true;
const PROD_SENTRY = true;

const config = {
  auth: {
    getCookieDomain: () => (window.location.hostname === 'gamma.freefeed.net' ? '.freefeed.net' : window.location.hostname),
    tokenPrefix: PROD_API ? 'freefeed_' : 'freefeed_dev_',
    userStorageKey: 'USER_KEY'
  },
  captcha: {
    siteKey: PROD_API ? '6LdChhITAAAAAGzgvUPPCbg0cxJzcxFc5vlBs9u5' : ''
  },
  googleAnalytics: {
    trackingId: 'UA-43039415-3'
  },
  sentry: {
    publicDSN: PROD_SENTRY ? 'https://2c3fac896af8434aba36d748dc6caddf@app.getsentry.com/76345' : 'https://0123456789abcdef0123456789abcdef@app.getsentry.com/12345'
  },
  siteDomains: [ // for transform links in the posts, comments, etc.
    'freefeed.net',
    'beta.freefeed.net',
    'gamma.creagenics.com',
    'gamma.freefeed.net',
    'm.freefeed.net',
    'old.freefeed.net'
  ],
  frontendPreferences: {
    clientId: 'net.freefeed',
    defaultValues: {
      displayNames: {
        displayOption: 1, // see the options in src/utils/frontend-preferences-options.js
        useYou: true
      },
      realtimeActive: false,
      comments: {
        highlightComments: true
      }
    }
  },
  api: {
    host: PROD_API ? 'https://freefeed.net' : 'http://localhost:3000'//'http://192.168.1.180:3000' //'https://frf-api.applied.creagenics.com'
  }
};

module.exports = config;
