import React from 'react';
import { Link } from 'react-router';
import { useSelector } from 'react-redux';

import { getVisibleEntriesWithHidden } from '../redux/selectors';
import PaginatedView from './elements/paginated-view';
import Feed from './elements/feed';

const PostBacklinksPage = (props) => {
  const pageView = useSelector(state => state.pageView);
  const isLoading = useSelector(state => state.routeLoadingState);
  const visibleEntries = useSelector(getVisibleEntriesWithHidden);

  const { params } = props;
  const postUrl = `/${params.userName}/${params.postId}`;

  return (
    <div className="box">
      <div className="box-header-timeline">
        References to <Link to={postUrl}>{postUrl}</Link>

        {pageView.number > 1 ? (
          <div className="pull-right">
            <span className="subheader">Page {pageView.number}</span>
          </div>
        ) : false}
      </div>

      {isLoading || visibleEntries.length ? (
        <PaginatedView pageView={pageView} {...props}>
          <Feed isLoading={isLoading} visibleEntries={visibleEntries} {...props}/>
        </PaginatedView>
      ) : (
        <div>
          <h4>No entries here</h4>
          <p>Either references were removed, or something went wrong.</p>
        </div>
      )}
    </div>
  );
};

export default PostBacklinksPage;
