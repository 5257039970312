import React from 'react';
import { Link } from 'react-router';
import { useSelector } from 'react-redux';

import { pluralForm } from '../../utils';
import 'styles/backlinks.scss';

const PostBacklinks = ({ postId, postUrl }) => {
  const backlinksCount = useSelector(state => state.posts[postId].backlinksCount);

  if (!backlinksCount) {
    return null;
  }

  return (
    <div className="backlinks">
      ↪
      {' '}
      <Link to={`${postUrl}/backlinks`}>
        {pluralForm(backlinksCount, 'reference')}
      </Link>
      {' '}
      to this post
    </div>
  );
};

export default PostBacklinks;
