import React from 'react';

export default () => (
  <div className="box">
    <div className="box-header-timeline">
      Terms of Service
    </div>
    <div className="box-body">
      <br/>

      <p><i>Last updated: October 31, 2016</i></p>

      <p>Please read these Terms of Service ("Terms", "Terms of Use") carefully before using
        the <a href="https://freefeed.net">https://freefeed.net</a> website (the "Service")
        operated by FreeFeed MTÜ ("us", "we", or "our").</p>

      <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms.
        These Terms apply to all visitors, users and others who access or use the Service.</p>

      <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of
        the terms then you may not access the Service.</p>

      <p>FreeFeed reserves the right to change these Terms at any time. We recommend that you periodically check
        this Site for changes.</p>

      <p>Without advance notice and at any time, we may, for violations of these Terms or for any other reason
        we choose: (1) suspend your access to the Service, (2) suspend or terminate your Account, and/or (3)
        remove any of your User Content from the Service.</p>

      <h3>Content</h3>

      <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text,
        graphics, videos, or other material ("Content"). You are responsible for the Content that you post to the
        Service, including its legality, reliability, and appropriateness.</p>

      <p>You own all of the content and information you post on the Service, and you can control who gets access to it
        through your privacy settings.</p>

      <p>With respect to photos, graphics, audio, video and other content you make available on the Service, you grant
        the Service worldwide, royalty-free and non-exclusive license to use, distribute, publish, translate,
        reproduce, modify, adapt, publicly perform and publicly display such Content (in whole or in part) on
        the Service solely for the purpose of displaying, hosting, providing access to, distributing, and
        promoting your posts, comments and other Content. This license exists only for as long as you elect
        to continue to include such Content on the Service and will terminate at the time you remove or
        FreeFeed MTÜ removes such Content from the Service.</p>

      <p>You agree that this license includes the right for us to make your Content available to other users
        of the Service, who may also use your Content subject to these Terms.</p>

      <h3>Copyright Policy</h3>

      <p>We respect the intellectual property rights of others. It is our policy to respond to any claim that
        Content posted on the Service infringes the copyright or other intellectual property infringement
        ("Infringement") of any person.</p>

      <p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted
        work has been copied in a way that constitutes copyright infringement that is taking place through
        the Service, you must submit your notice in writing to the attention of "Copyright Infringement"
        of freefeed.net@gmail.com and include in your notice a detailed description of the alleged
        Infringement.</p>

      <p>You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any
        Content is infringing your copyright.</p>

      <h3>Intellectual Property</h3>

      <p>The Service and its original content (excluding Content provided by users), features and functionality are and
        will remain the exclusive property of FreeFeed MTÜ and its licensors. The Service is protected by copyright,
        trademark, and other laws of both Estonia and foreign countries. Our trademarks and trade dress may not be used
        in connection with any product or service without the prior written consent of FreeFeed MTÜ.</p>

      <h3>Links To Other Web Sites</h3>

      <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by
        FreeFeed MTÜ.</p>

      <p>FreeFeed MTÜ has no control over, and assumes no responsibility for, the content, privacy policies, or
        practices of any third party web sites or services. You further acknowledge and agree that FreeFeed MTÜ shall
        not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused
        by or in connection with use of or reliance on any such content, goods or services available on or through
        any such web sites or services.</p>

      <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or
        services that you visit.</p>

      <h3>Limitation Of Liability</h3>

      <p>In no event shall FreeFeed MTÜ, nor its directors, employees, partners, agents, suppliers, or affiliates, be
        liable for any indirect, incidental, special, consequential or punitive damages, including without limitation,
        loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of
        or inability to access or use the Service; (ii) any conduct or content of any third party on the Service;
        (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your
        transmissions or content, whether based on warranty, contract, tort (including negligence) or any other
        legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy
        set forth herein is found to have failed of its essential purpose.</p>

      <h3>Disclaimer</h3>

      <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis.
        The Service is provided without warranties of any kind, whether express or implied, including, but not
        limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or
        course of performance.</p>

      <p>FreeFeed MTÜ, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will
        function uninterrupted, secure or available at any particular time or location; b) any errors or defects
        will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of
        using the Service will meet your requirements.</p>

      <h3>Governing Law</h3>

      <p>These Terms shall be governed and construed in accordance with the laws of Estonia, without regard to its
        conflict of law provisions.</p>

      <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
        If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our
        Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>

      <h3>Changes</h3>

      <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is
        material we will try to provide at least 30 days notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.</p>

      <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by
        the revised terms. If you do not agree to the new terms, please stop using the Service.</p>

      <h3>Contact Us</h3>

      <p>If you have any questions about these Terms, please <a href="mailto:freefeed.net@gmail.com">contact us</a>.</p>
    </div>
  </div>
);
